import React from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  GlobeAltIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/outline';
import { JobsModel } from '../../models/jobs';

interface JobListProps {
  jobs: JobsModel[];
  selectedJob: JobsModel | null;
  onJobClick: (job: JobsModel) => void;
  currentPage: number;
  totalPages: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  onPageClick: (pageNumber: number) => void;
  className?: string; // allow className prop
}

const JobList: React.FC<JobListProps> = ({
  jobs,
  selectedJob,
  onJobClick,
  currentPage,
  totalPages,
  onNextPage,
  onPrevPage,
  onPageClick,
  className,
}) => {
  const getPageNumbers = () => {
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const getSourceBadge = (source: string) => {
    if (source.toLowerCase() === 'google.com') {
      return (
        <div className="flex items-center space-x-1 text-gray-500">
          <GlobeAltIcon className="w-5 h-5" />
          <span>Google</span>
        </div>
      );
    }
    if (source === 'Indeed.com') {
      return (
        <div className="flex items-center space-x-1 text-blue-600">
          <BriefcaseIcon className="w-5 h-5" />
          <span>Indeed</span>
        </div>
      );
    }
    return <span className="text-gray-500 text-xs">Other</span>;
  };

  return (
    <div className={`overflow-y-auto px-4 rounded-xl ${className}`}>
      <div className="space-y-4">
        {jobs.map((job) => (
          <div
            key={job.id}
            className={`border border-gray-300 rounded-lg p-4 shadow-lg hover:shadow-xl transition cursor-pointer ${
              selectedJob?.id === job.id
                ? 'bg-blue-100 border-2 border-blue-400'
                : 'bg-white'
            }`}
            onClick={() => onJobClick(job)}
          >
            <div className="relative">
              <h2 className="text-xl font-semibold">{job.title}</h2>
              <p className="text-gray-700">{job.company}</p>
              <p className="text-gray-500">{job.employment_type}</p>
              <p className="text-gray-500">{job.salary}</p>
              <div className="absolute bottom-2 right-2 opacity-40 pointer-events-none">
                {getSourceBadge(job.source)}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={onPrevPage}
          disabled={currentPage === 1}
          className={`p-2 rounded-md ${
            currentPage === 1
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>

        <div className="flex space-x-2">
          {getPageNumbers().map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => onPageClick(pageNumber)}
              className={`p-2 rounded-md ${
                currentPage === pageNumber
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-300 text-gray-500'
              }`}
            >
              {pageNumber}
            </button>
          ))}
        </div>

        <button
          onClick={onNextPage}
          disabled={currentPage === totalPages}
          className={`p-2 rounded-md ${
            currentPage === totalPages
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default JobList;
