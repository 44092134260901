import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import Jobs from './components/Jobs/Jobs';
import Blog from './components/Blog/Blog';
import Navbar from './components/NavBar/NavBar';
import Menubar from './components/MenuBar/MenuBar';
import Footer from './components/Footer/Footer';
import Post1 from './components/Blog/Post1';
import Post2 from './components/Blog/Post2';
import Profile from './components/Profile/Profile';
import CoverLetter from './components/CoverLetter/CoverLetter';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow w-full max-w-full bg-slate-200 bg-opacity-80">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/cover-letter" element={<CoverLetter />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post1" element={<Post1 />} />
          <Route path="/blog/post2" element={<Post2 />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </div>
      <Footer />
      <Menubar />
    </div>
  );
}

export default App;
