import React, { useEffect, useState } from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../middleware/AuthContext';
import SignIn from '../SignIn/SignIn';
import api from '../../services/api';

const GoogleFormEmbed: React.FC = () => (
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSfryxj7AAIHSkiKIgJ_B-j7wRlwz1XZFBd0SAOCBDStyV-vlA/viewform?embedded=true"
    width="100%"
    height="500"
    className="rounded-lg border"
    title="Feedback Form"
  >
    Loading…
  </iframe>
);

const Navbar: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown visibility
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthFromUrl();
  }, []);

  const checkAuthFromUrl = () => {
    const authParam = searchParams.get('auth');
    if (authParam === 'true') {
      console.log('Auth param detected in URL');
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
    }
  };

  const handleSignInClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogoutClick = () => {
    api
      .post('/auth/logout', {}, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          setIsAuthenticated(false);
          localStorage.setItem('isAuthenticated', 'false');
          console.log('Logged out successfully');
          // navigate to home page
          navigate('/');
        } else {
          console.error('Failed to log out');
        }
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  const handleFormButtonClick = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    setIsDropdownOpen(false); // Close dropdown after navigating
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="bg-gray-800 border-b-2 border-white">
      <nav className="px-4 py-2 flex items-center justify-between border-b-2 border-gray-100 shadow-sm">
        <div className="flex items-center">
          <h1
            className="text-4xl font-bold text-white font-poppins"
            style={{
              textShadow:
                '2px 2px 0px #3b82f6, -2px -2px 0px #3b82f6, 2px -2px 0px #3b82f6, -2px 2px 0px #3b82f6',
            }}
          >
            talium
          </h1>
        </div>

        <div className="flex items-center space-x-6">
          <button
            onClick={handleFormButtonClick}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Feedback
          </button>

          {isAuthenticated ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center space-x-2 text-white"
              >
                <Bars3Icon className="w-8 h-8" />
              </button>

              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-50">
                  <button
                    onClick={handleProfileClick}
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                  >
                    Profile
                  </button>
                  <button
                    onClick={handleLogoutClick}
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button
              onClick={handleSignInClick}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Sign In
            </button>
          )}
        </div>
      </nav>

      {isFormModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl w-full relative">
            <button
              onClick={handleCloseFormModal}
              className="absolute top-5 right-5 text-gray-500 hover:text-black"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
            <GoogleFormEmbed />
          </div>
        </div>
      )}

      {isModalOpen && <SignIn onClose={handleCloseModal} />}
    </div>
  );
};

export default Navbar;
