import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  BriefcaseIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';

type MenuOption = 'Home' | 'Jobs' | 'Blog' | 'CoverLetter';

const Menubar: React.FC = () => {
  const [clicked, setClicked] = useState<MenuOption>('Home');
  const location = useLocation();

  useEffect(() => {
    const pathToMenuOption = (path: string): MenuOption => {
      if (path.startsWith('/jobs')) {
        return 'Jobs';
      } else if (path.startsWith('/blog')) {
        return 'Blog';
      } else if (path.startsWith('/cover-letter')) {
        return 'CoverLetter';
      } else {
        return 'Home';
      }
    };

    setClicked(pathToMenuOption(location.pathname));
  }, [location]);

  return (
    <div className="fixed bottom-2 left-1/2 transform -translate-x-1/2 z-30 w-[40vh] h-16 bg-blue-400 bg-opacity-60 rounded-3xl flex justify-center items-center space-x-6">
      {/* Blog */}
      <Link to="/blog" className="group relative">
        <button
          className={`flex items-center justify-center py-2 transition-colors duration-200 ${
            clicked === 'Blog' ? 'text-blue-800' : 'text-white'
          }`}
          onClick={() => setClicked('Blog')}
          aria-label="Blog"
        >
          <ChatBubbleOvalLeftEllipsisIcon className="h-8 w-8" />
        </button>
      </Link>

      {/* Home */}
      <Link to="/" className="group relative">
        <button
          className={`flex items-center justify-center py-2 transition-colors duration-200 ${
            clicked === 'Home' ? 'text-blue-800' : 'text-white'
          }`}
          onClick={() => setClicked('Home')}
          aria-label="Home"
        >
          <HomeIcon className="h-8 w-8" />
        </button>
      </Link>

      <div className="text-blue-800 text-3xl">|</div>

      {/* Jobs */}
      <Link to="/jobs" className="group relative">
        <button
          className={`flex items-center justify-center py-2 transition-colors duration-200 ${
            clicked === 'Jobs' ? 'text-blue-800' : 'text-white'
          }`}
          onClick={() => setClicked('Jobs')}
          aria-label="Jobs"
        >
          <BriefcaseIcon className="h-8 w-8" />
        </button>
      </Link>

      {/* Cover Letter */}
      <Link to="/cover-letter" className="group relative">
        <button
          className={`flex items-center justify-center py-2 transition-colors duration-200 ${
            clicked === 'CoverLetter' ? 'text-blue-800' : 'text-white'
          }`}
          onClick={() => setClicked('CoverLetter')}
          aria-label="Cover Letter"
        >
          <DocumentIcon className="h-8 w-8" />
        </button>
      </Link>
    </div>
  );
};

export default Menubar;
