import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import JobList from './JobList';
import JobDetails from './JobDetails';
import { JobsModel } from '../../models/jobs';
import { useAuth } from '../../middleware/AuthContext';
import SignIn from '../SignIn/SignIn';
import api from '../../services/api';
import Modal from './Modal';
import useMediaQuery from './MediaQuery';

const sourceOptions = [
  { value: 'Google.com', label: 'Google' },
  { value: 'Indeed.com', label: 'Indeed' },
];

const Jobs: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [jobs, setJobs] = useState<JobsModel[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobsModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const jobsPerPage = 15;

  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>(searchParams.get('title') || '');
  const [source, setSource] = useState<string>(
    searchParams.get('source') || ''
  );
  const [location, setLocation] = useState<string>(
    searchParams.get('location') || ''
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Detect if the screen is medium (tablet) or smaller (mobile)
  const isMobileOrTablet = useMediaQuery('(max-width: 1023px)'); // Tailwind's `lg` breakpoint is 1024px

  // Additional state to control JobDetails modal on mobile/tablet
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  // Open the sign-in modal if the user is not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  // Fetch jobs on initial load and when search parameters change
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/jobs/getjobs?title=${encodeURIComponent(title)}&source=${encodeURIComponent(source)}&location=${encodeURIComponent(location)}`
        );
        setJobs(response.data);
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          setError(`Failed to fetch job listings. Please try again later.`);
        } else {
          setError(`An unexpected error occurred. Please try again later.`);
        }
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchJobs();
    }
  }, [searchParams, isAuthenticated]);

  // Pagination logic
  const totalPages = Math.ceil(jobs.length / jobsPerPage);
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  // Handle form changes for search inputs
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);
  const handleSourceChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSource(e.target.value);
  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLocation(e.target.value);

  // Update the search parameters in the URL on form submit
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchParams({
      title: title.trim(),
      source: source.trim(),
      location: location.trim(),
    });
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleJobClick = (job: JobsModel) => {
    setSelectedJob(job);
    if (isMobileOrTablet) {
      setIsDetailsModalOpen(true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (!isAuthenticated) {
    return isModalOpen ? (
      <SignIn onClose={() => setIsModalOpen(false)} />
    ) : null;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Cog6ToothIcon className="h-16 w-16 text-gray-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div>
      <SearchBar
        title={title}
        source={source}
        location={location}
        handleTitleChange={handleTitleChange}
        handleSourceChange={handleSourceChange}
        handleLocationChange={handleLocationChange}
        handleSearchSubmit={handleSearchSubmit}
        sourceOptions={sourceOptions}
      />
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 py-1">
        <JobList
          jobs={currentJobs}
          selectedJob={selectedJob}
          onJobClick={handleJobClick}
          currentPage={currentPage}
          totalPages={totalPages}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
          onPageClick={handlePageClick}
          className="lg:col-span-2"
        />

        {/* JobDetails for Desktop */}
        {!isMobileOrTablet && (
          <JobDetails job={selectedJob} className="lg:col-span-4" />
        )}
      </div>

      {/* Modal for Job Details on Mobile/Tablet */}
      {isMobileOrTablet && selectedJob && (
        <Modal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
        >
          <JobDetails job={selectedJob} />
        </Modal>
      )}
    </div>
  );
};

export default Jobs;
