import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import {
  UserProfile,
  WorkExperience,
  Education,
  Certification,
  Skill,
} from '../../models/profile';
import { MinusCircleIcon } from '@heroicons/react/24/outline';

const Profile: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [formData, setFormData] = useState<UserProfile>({
    email: '',
    name: '',
    summary: '',
    street_address: '',
    city: '',
    state: '',
    zip: '',
    work_experience: [],
    education: [],
    skills: [],
    certifications: [],
    last_login: '',
    created_at: '',
    updated_at: '',
  });

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/user/getuser');
        console.log('Fetched profile data:', response.data); // Log API response
        setProfile(response.data);
        setFormData(response.data);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile information.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleArrayChange = (
    index: number,
    field:
      | keyof WorkExperience
      | keyof Education
      | keyof Certification
      | keyof Skill,
    value: string,
    section: 'work_experience' | 'education' | 'certifications' | 'skills'
  ) => {
    const updatedArray = formData[section]?.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    ) as WorkExperience[] | Education[] | Certification[] | Skill[];
    setFormData({ ...formData, [section]: updatedArray });
  };

  const handleAddItem = (
    section: 'work_experience' | 'education' | 'certifications' | 'skills'
  ) => {
    let newItem;

    switch (section) {
      case 'work_experience':
        newItem = { jobTitle: '', company: '', duration: '', description: '' };
        break;
      case 'education':
        newItem = { school: '', degree: '', field: '', graduationYear: '' };
        break;
      case 'certifications':
        newItem = {
          name: '',
          issuingOrganization: '',
          issueDate: '',
          expirationDate: '',
        };
        break;
      case 'skills':
        newItem = '';
        break;
      default:
        throw new Error(`Unsupported section: ${section}`);
    }

    setFormData({
      ...formData,
      [section]: [...(formData[section] || []), newItem],
    });
  };

  const handleRemoveItem = (
    section: 'work_experience' | 'education',
    index: number
  ) => {
    const updatedArray =
      section === 'work_experience'
        ? (formData[section] as WorkExperience[]).filter((_, i) => i !== index)
        : (formData[section] as Education[]).filter((_, i) => i !== index);

    setFormData({ ...formData, [section]: updatedArray });
  };

  const handleSave = async () => {
    try {
      const response = await api.post('/user/update', formData);
      setProfile(response.data);
      setEditMode(false);
    } catch (err) {
      console.error('Error saving profile:', err);
      setError('Failed to save profile information.');
    }
  };

  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-white mx-2 my-4 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-blue-500">
        Profile Information
      </h2>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <h3>
            <strong>Email:</strong>
          </h3>
          <p className="bg-gray-400 rounded-lg py-2 px-4 border-2 border-gray-300">
            {profile?.email}
          </p>
        </div>
        <div>
          <h3>
            <strong>Name:</strong>
          </h3>
          {editMode ? (
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              className="w-full px-2 py-1 rounded-lg border-2 border-gray-300"
            />
          ) : (
            <p className="border-2 border-gray-300 rounded-lg w-full px-4 py-2">
              {profile?.name || 'Not provided'}
            </p>
          )}
        </div>
        <div>
          <h3>
            <strong>Address:</strong>
          </h3>
          {editMode ? (
            <div className="space-y-2">
              <input
                type="text"
                name="street_address"
                value={formData.street_address || ''}
                onChange={handleChange}
                placeholder="Street Address"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="city"
                value={formData.city || ''}
                onChange={handleChange}
                placeholder="City"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="state"
                value={formData.state || ''}
                onChange={handleChange}
                placeholder="State"
                className="w-full px-2 py-1 border rounded-md"
              />
              <input
                type="text"
                name="zip"
                value={formData.zip || ''}
                onChange={handleChange}
                placeholder="ZIP Code"
                className="w-full px-2 py-1 border rounded-md"
              />
            </div>
          ) : (
            <p className="border-2 border-gray-300 rounded-lg w-full px-4 py-2 min-h-[2.6rem]">
              {profile?.street_address} {profile?.city} {profile?.state}
              {profile?.zip}
            </p>
          )}
        </div>
      </div>

      <div className="mb-6">
        <strong>Summary:</strong>
        {editMode ? (
          <textarea
            name="summary"
            value={formData.summary || ''}
            onChange={handleChange}
            className="w-full px-2 py-1 border rounded-md"
          />
        ) : (
          <p>{profile?.summary || 'Not provided'}</p>
        )}
      </div>

      <div className="grid grid-cols-3 gap-6">
        {/* Work Experience */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">
            Work Experience
          </h3>
          {formData.work_experience?.map((exp, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Job Title"
                    value={exp.jobTitle}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'jobTitle',
                        e.target.value,
                        'work_experience'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Company"
                    value={exp.company}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'company',
                        e.target.value,
                        'work_experience'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <textarea
                    placeholder="Description"
                    value={exp.description}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'description',
                        e.target.value,
                        'work_experience'
                      )
                    }
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() => handleRemoveItem('work_experience', index)}
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{exp.jobTitle}</p>
                  <p>{exp.company}</p>
                  <p>{exp.description}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('work_experience')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Work Experience
            </button>
          )}
        </div>

        {/* Education */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">Education</h3>
          {formData.education?.map((edu, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="School"
                    value={edu.school}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'school',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Degree"
                    value={edu.degree}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'degree',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <textarea
                    placeholder="Field of Study"
                    value={edu.field}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'field',
                        e.target.value,
                        'education'
                      )
                    }
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() => handleRemoveItem('education', index)}
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{edu.degree}</p>
                  <p>{edu.school}</p>
                  <p>{edu.field}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('education')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Education
            </button>
          )}
        </div>

        {/* Skills */}
        <div>
          <h3 className="text-lg font-bold mb-4 text-blue-500">Skills</h3>
          {formData.skills?.map((skill, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Skill Name"
                    value={skill.name}
                    onChange={(e) =>
                      handleArrayChange(index, 'name', e.target.value, 'skills')
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Proficiency (e.g., Beginner, Intermediate, Expert)"
                    value={skill.proficiency}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'proficiency',
                        e.target.value,
                        'skills'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() =>
                      setFormData({
                        ...formData,
                        skills: formData.skills?.filter((_, i) => i !== index),
                      })
                    }
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{skill.name}</p>
                  <p>{skill.proficiency}</p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() =>
                setFormData({
                  ...formData,
                  skills: [
                    ...(formData.skills || []),
                    { name: '', proficiency: '' },
                  ],
                })
              }
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Skill
            </button>
          )}
          {/* Certifications */}
          <h3 className="text-lg font-bold mb-4 text-blue-500 mt-12">
            Certifications
          </h3>
          {formData.certifications?.map((cert, index) => (
            <div
              key={index}
              className="mb-4 p-3 bg-gray-100 rounded-lg shadow-sm relative"
            >
              {editMode ? (
                <>
                  <input
                    type="text"
                    placeholder="Certification Name"
                    value={cert.name}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'name',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Issuing Organization"
                    value={cert.issuingOrganization}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'issuingOrganization',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="Issue Date"
                    value={cert.issueDate}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'issueDate',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 mb-2 border rounded-md"
                  />
                  <input
                    type="date"
                    placeholder="Expiration Date"
                    value={cert.expirationDate}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        'expirationDate',
                        e.target.value,
                        'certifications'
                      )
                    }
                    className="w-full px-2 py-1 border rounded-md"
                  />
                  <MinusCircleIcon
                    onClick={() =>
                      setFormData({
                        ...formData,
                        certifications: formData.certifications?.filter(
                          (_, i) => i !== index
                        ),
                      })
                    }
                    className="h-6 w-6 text-red-500 absolute top-2 right-2 cursor-pointer hover:text-red-600"
                  />
                </>
              ) : (
                <>
                  <p className="font-semibold">{cert.name}</p>
                  <p>{cert.issuingOrganization}</p>
                  <p>
                    {cert.issueDate} - {cert.expirationDate || 'Present'}
                  </p>
                </>
              )}
            </div>
          ))}
          {editMode && (
            <button
              onClick={() => handleAddItem('certifications')}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Add Certification
            </button>
          )}
        </div>
      </div>

      <div className="mt-6 text-right">
        <button
          onClick={editMode ? handleSave : () => setEditMode(true)}
          className="px-6 py-2 bg-blue-500 text-white rounded-md"
        >
          {editMode ? 'Save' : 'Edit'}
        </button>
      </div>
    </div>
  );
};

export default Profile;
