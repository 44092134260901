import React from 'react';
import { JobsModel } from '../../models/jobs';

interface JobDetailsProps {
  job: JobsModel | null;
  className?: string; // allow className prop
}

const JobDetails: React.FC<JobDetailsProps> = ({ job, className }) => {
  if (!job) {
    return (
      <div
        className={`flex justify-center items-center text-center text-gray-500 h-full ${className}`}
      >
        <p className="text-xl text-center">Select a job to view details</p>
      </div>
    );
  }

  return (
    <div
      className={`py-4 px-6 bg-white rounded-xl border-2 border-blue-400 overflow-y-auto ${className}`}
    >
      <div className="space-y-6">
        <h1 className="text-4xl font-bold text-blue-500">{job.title}</h1>
        <p className="text-2xl text-gray-600">{job.company}</p>
        <p className="text-sm text-blue-500">
          {new Date(job.posted_time).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}{' '}
          - {job.employment_type} - {job.salary}
        </p>
        <p className="mt-4 text-lg">{job.description}</p>
        <div className="mt-6">
          <h3 className="font-semibold text-xl">Responsibilities</h3>
          <p className="text-lg">{job.responsibilities}</p>
        </div>
        <div className="mt-6">
          <h3 className="font-semibold text-xl">Qualifications</h3>
          <p className="text-lg">{job.qualifications}</p>
        </div>
        <div className="mt-6">
          <h3 className="font-semibold text-xl">Benefits</h3>
          <p className="text-lg">{job.benefit}</p>
        </div>
        <a
          href={job.apply_links}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 inline-block bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default JobDetails;
