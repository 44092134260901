import React from 'react';

interface SearchBarProps {
  title: string;
  source: string;
  location: string;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSourceChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleLocationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (e: React.FormEvent) => void;
  sourceOptions: { value: string; label: string }[];
}

const SearchBar: React.FC<SearchBarProps> = ({
  title,
  source,
  location,
  handleTitleChange,
  handleSourceChange,
  handleLocationChange,
  handleSearchSubmit,
  sourceOptions,
}) => {
  return (
    <div className="p-6">
      <form className="grid grid-cols-8 gap-4" onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Search job title"
          value={title}
          onChange={handleTitleChange}
          className="col-span-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
        />
        <select
          value={source}
          onChange={handleSourceChange}
          className="col-span-1 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
        >
          <option value="">Select Source</option>
          {sourceOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search by location"
          value={location}
          onChange={handleLocationChange}
          className="col-span-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
        />
        <button
          type="submit"
          className="col-span-1 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
